
<template>
    <div class="text-center">
      <div class="spacer"></div>
      <div class="main container">



        <div style="position: absolute; top: 60px; left: 50px;">
          <router-link to="/" class="btn btn-primary">Go To My Apps</router-link>
        </div>

        <div class="mt-5 mb-4">
          <h2>{{ app.name }}</h2>
          <h5><a :href="'/apps/edit/'+app._id.$oid">{{ app.description }}</a></h5>
          <span style="position: absolute; top: 20px; left: 50px;">{{ app.app_key }}</span>
        </div>

        <div class="search">
          <div>
            <span class="label">Date:</span>
            <input type="date" v-model="date"></input>
            <!-- <select name="file" v-model="file">
              <option v-for="f in files" :value="f">{{ f }}</option>
            </select> -->
          </div>
          <div>
            <span class="label"></span>
            <input type="submit" :value="btnValue" class="btn btn-primary" @click="fetchLogs"></input>
          </div>
          <hr>
          <div>
            <span class="label">Channel: </span>
              <select name="channel" v-model="channel">
                <option value=""></option>
                <option v-for="channel in channels" :value="channel">{{channel}}</option>
              </select>
          </div>
          <div>
            <span class="label">Level:</span>
            <select name="level" v-model="level">
              <option value=""></option>
              <option value="DEBUG">DEBUG</option>
              <option value="INFO">INFO</option>
              <option value="NOTICE">NOTICE</option>
              <option value="WARNING">WARNING</option>
              <option value="ERROR">ERROR</option>
              <option value="CRITICAL">CRITICAL</option>
              <option value="ALERT">ALERT</option>
              <option value="EMERGENCY">EMERGENCY</option>
            </select>
          </div>

          <div>
            <span class="label">Search: </span>
              <input name="search" v-model="search"></input>
          </div>

        </div>
        <div class="mt-3">
          <h4 class="text-secondary">{{ rows.length }} rows</h4>
        </div>

        <table class="table-striped" v-if="rows">
            <thead>
              <tr>
                <th class="secondary" style="width: 5vw">Channel</th>
                <th class="secondary" style="width: 10vw">Timestamp</th>
                <th class="secondary" style="width: 5vw">Level</th>
                <th class="secondary" style="width: 5vw">Level</th>
                <th class="secondary" style="width: 50vw">Message</th>
                <th class="secondary" style="width: 5vw"></th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="row,idx in logsFiltered">
                <td style="width: 12vw">{{ row.channel }}</td>
                <td style="width: 15vw">{{ row.datetime.date }}</td>
                <td style="width: 4vw">{{ row.level }}</td>
                <td style="width: 6vw">{{ row.level_name }}</td>
                <td style="text-align:left;width:50vw;overflow:hidden;text-overflow:ellipsis;">{{ row.message }}</td>
                <!-- <td><a :href="loglink(row.id)">show</a></td> -->
                <td style="width: 5vw"><a href="" @click="loglink(idx)">show</a></td>
              </tr>

            </tbody>
        </table>
        <div class="loader" v-if="loading">
          <pulse-loader :color="'#74b3e0'" :loading="loading" :size="20" :sizeUnit="'px'"></pulse-loader>
        </div>

      </div>
    </div>
</template>

<script>
import { PulseLoader } from '@saeris/vue-spinners'

export default {
  props: ['logo','size', 'margin', 'endpoint'],
  name: 'logs',
  data: function() {
    return {
      app: {'_id': { '$oid': ''}},
      rows: [],
      search: '',
      level: '',
      channel: '',
      date: new Date().toISOString().slice(0, 10),
      loading: false

    }
  },
  computed: {
    query() {
      var output = "?";
      // if(this.search.length) output += 'search='+this.search+'&';
      // if(this.level.length) output += 'level='+this.level+'&';
      output += 'date='+this.date;
      if(output.length>1) return output;
      else return "";
    },
    app_id() {
      return this.$route.params.id;
    },
    channels() {
      var output = [];
      for(var n=0; n<this.rows.length; n++) {
        if(output.includes(this.rows[n].channel)) continue;
        output.push(this.rows[n].channel)
      }
      return output;
    },
    logsFiltered() {
      var that = this;
      return this.rows.filter(function(row){
        if(that.channel.length && that.channel != row.channel) return false;
        if(that.level.length && that.level != row.level_name) return false;
        if(that.search.length && row.message.toLowerCase().indexOf(that.search.toLowerCase()) == -1) return false;
        return true;
      }).reverse();
    },
    lastTimestamp() {
      if(!this.rows.length) return null;
      return this.rows[this.rows.length - 1].datetime.date;
    },
    dateRetreived() {
      if(!this.rows.length) return null;
      return this.rows[this.rows.length - 1].datetime.date.slice(0,10);
    },
    btnValue() {
      if(this.date == this.dateRetreived) return "Refresh";
      else return "Retreive";
    }
  },
  methods: {
    fetchApp() {
      fetch('/api/apps/' + this.app_id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + window.Auth.token()
        }
      })
      .then(res => checkResponse(res))
      .then(res => res.json())
      .then(res => {
        console.log(res);
        this.app = res;
      })
      .catch(err => console.log(err))
    },
    fetchLogs() {
      var url = '/api/logs/' + this.app.app_key + '?date=' + this.date;
      // if(this.date == this.dateRetreived) {
      if(this.rows.length) {
        // url += '&timestamp=' + this.lastTimestamp;
        url += '&skip=' + this.rows.length;
      }
      this.loading = true;
      fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + window.Auth.token()
        }
      })
      .then(res => checkResponse(res))
      .then(res => res.json())
      .then(res => {
        if(res==null) {
          this.loading=false;
        }
        else {
          console.log(res.records);
          if(this.date == this.dateRetreived) {
            for(var r in res.records) {
              this.rows.push(res.records[r]);
            }
          }
          else this.rows = res.records;
          this.loading = false;
        }

        // this.$forceUpdate();
      })
      .catch(err => console.log(err))
    },
    updateLogs() {
      this.loading = true;
      fetch('/api/logs/' + this.app.app_key + this.query + '&timestamp=' + this.lastTimestamp, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + window.Auth.token()
        }
      })
      .then(res => checkResponse(res))
      .then(res => res.json())
      .then(res => {
        console.log(res.records);

        this.rows.push(res.records);
        this.loading = false;
        // this.$forceUpdate();
      })
      .catch(err => console.log(err))
    },
    loglink(index) {
      event.preventDefault();
      console.log("index is: " + index);
      console.log(this.logsFiltered[index]);
      this.$router.push({
        // path: 'logs/' + this.rows[index].id,
        name: 'showLog',
        params: {
          log: this.logsFiltered[index],
          id: this.logsFiltered[index].id
        }
      })
    }
  },
  created: function() {

    // console.log(this.$route.query.to);
    // console.log("endpoint: " + this.endpoint);
    this.fetchApp();

    // setInterval(()=>{
    //     this.fetchLogs()  ;
    // },15000);

  },
  components: {
    PulseLoader
  }
}
</script>

<style scoped>
.search {
  display: inline-block;
  text-align: center;
  margin: 10px;
  /* border: 1px solid #ccc; */
  background: #f9f9fc;
  border-radius: 6px;
  padding: 25px;

}
.search div {
  margin: 6px;
}
.search .label {
  width: 60px;
  display: inline-block;
  text-align: right;
  /* border: 1px solid red; */
}
input, select {
  width: 300px;
  padding: 5px;
}
td {
  padding: 5px;
}
.main {
  display: inline-block;
  max-width: 80vw;
}
th {
  color: white;
  background: #475869;
  padding: 10px;
  font-weight: normal;
  position: sticky;
  top: 46px;
}
table {
  width: 100% !important;
}
.loader {
  position: fixed;
  right: 3%;
  top: 3%;
  /* transform: translate(-50%, -65%); */
}
.spacer {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 46px;
  background-color: #fff;
}

</style>
