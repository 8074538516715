
<template>
  <div style="text-align:center; position: absolute; left: 50%; top: 45%; transform: translate(-50%, -50%);">

    <div class="row mb-4">
      <img :src="logo" onerror="this.style.display='none'" :style="{width: size, marginBottom: margin}">
    </div>


    <div class="row" style="display:inline-block">

        <div class="card card-body bg-light mb-4 mt-5 p-4" style="display:inline-block">
          <div class="form-group">
            <input type="text" class="form-control" v-model="login" placeholder="Login" @keyup.enter="$refs.submitbutton.click()">
          </div>
          <div class="form-group">
            <input type="password" class="form-control" v-model="password" placeholder="Password" @keyup.enter="$refs.submitbutton.click()">
          </div>
          <div class="text-danger mb-4" v-text="error" v-if="error.length"></div>
          <div class="text-center">
            <button @click="do_login" class="btn btn-primary m-auto" ref="submitbutton"><i class="fa fa-check"></i> Login</button>
          </div>
        </div>

    </div>
  </div>
</template>

<script>

export default {
  props: ['logo','size', 'margin', 'endpoint'],
  name: 'login',
  data: function() {
    return {
      login: '',
      password: '',
      error: ''
    }
  },
  methods: {
    do_login() {
      fetch(this.endpoint, {
        method: 'post',
        body: JSON.stringify({
          username: this.login,
          password: this.password
        }),
        headers: {
          'content-type': 'application/json'
        }
      })
      .then(res => checkResponse(res))
      .then(res => res.json())
      .then(res => {
        console.log(res);
        if(res.token) {
          // save user in session storage
          window.Auth.login(res.token);

          // force update
          this.$emit('login');

          // redirect to original path
          if(typeof(this.$route.query.to) !== 'undefined' && this.$route.query.to.length > 1) this.$router.replace({name: this.$route.query.to});
          else this.$router.replace('/');

          this.$router.go(0);  // refresh
        }
        else {
          this.error = res.error;
        }

        // wut
        if(res.messages) {
          this.$emit('messages',res.messages);
        }

        this.login = '';
        this.password = '';
      })
      .catch(err => console.log(err))
    }
  },
  created: function() {
    console.log(this.$route.query.to);
    console.log("endpoint: " + this.endpoint);
  },
  components: {

  }
}
</script>
