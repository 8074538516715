
// import vue
import Vue from 'vue'
import VueRouter from 'vue-router'
//import Vuex from 'vuex'

// assets
import logo from './../images/logo.png'
import bootstrap from 'bootstrap/dist/css/bootstrap';
// import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
// import classes from './../css/main.css';

// app
import App from './components/App-router.vue'
//import { store } from './store'

// components
// import menu from './components/menu.vue'  // imported in App

// views
// import {addRole} from 'vue-libs'
// import {addUser} from 'vue-libs'
// import changePassword from './components/changePassword'

// import editUser from './components/editUser'
import dashboard from './components/dashboard'
// import {dashboard} from 'vue-libs'
// import {history} from 'vue-libs'
// import {importFile} from 'vue-libs'
// import {lockout} from 'vue-libs'
// import {login} from 'vue-libs'
// import {logout} from 'vue-libs'
// import {uploadFile} from 'vue-libs'
// import updateUser from './components/updateUser'
// import user_roles from './components/user_roles'
// import users from './components/users'

// external views

// import gridView from './components/vue-grid-view'

// domain views

import notFound from './components/notFound.vue'
import logs from './components/logs.vue'
import log from './components/log.vue'
// import queue from './components/queue.vue'
// import queuesummary from './components/queuesummary.vue'
import error from './components/error.vue'

import appForm from './views/appForm.vue'

// utility views
import login from './components/login'
import logout from './components/logout'
// import changePassword from './views/changePassword.vue'
// import updateUser from './views/updateUser.vue'
// import privacyStatement from './views/privacyStatement.vue'
// import acceptance from './views/acceptance.vue'
// import tos from './views/tos.vue'

// set server location
// var server = 'http://localhost:9995/api';
// var server = 'http://localhost:9990';
var server = '';
// var server = 'https://pipelinedata.fractionsdata.com/api';
// var server = 'https://api.flownotices.com';

// remove console.log for npm run build
if (process.env.NODE_ENV === 'production') { // Or, `process.env.NODE_ENV !== 'production'`
  console.log = function(){};
  window.console = console;
}

// Sentry.init({ dsn: 'https://88dde8aa08b446aeb835db3f45ed4dff@o400737.ingest.sentry.io/5259413' });


// set up vue
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  //base: __dirname,
  base: '/',
  routes: [

    // common
    {path: '', component: dashboard, props: { title: 'Fractions Accounting', endpoint: server + '/apps'}, meta: { requireLogin: true }},
    {path: '/', component: dashboard, props: { title: 'Fractions Accounting', endpoint: server + '/apps'}, meta: { requireLogin: true }},
    {path: '/login', component: login, props: { title: 'FlowNotices', endpoint: server + '/login', logo: logo, size:'600px', margin:'15px' }},
    {path: '/logout', component: logout, props: { endpoint: server + '/logout' }},
    {path: '/error', component: error, props: { title: 'Error' }},
    {path: '/notFound', component: notFound, props: { title: '404 Not Found' }},

    // user functions
    // {path: '/changePassword', component: changePassword, props: { title: 'Update Password', endpoint: server + '/users'}, meta: { requireLogin: true }},
    // {path: '/updateUser', component: updateUser, props: { title: 'Update My Information', endpoints: {'users': server + '/users', 'user': server + '/user'}}, meta: { requireLogin: true }},
    // {path: '/privacyStatement', name: 'privacyStatement', component: privacyStatement, props: { title: 'Privacy Notice', endpoint: server + '/privacy'}, meta: { requireLogin: true }},
    // {path: '/tos', name: 'tos', component: tos, props: { title: 'Terms of Service', endpoint: server + '/tos'}, meta: { requireLogin: true }},
    // {path: '/acceptance', name: 'acceptance', component: acceptance, props: { title: 'Terms of Service', endpoint: server + '/tos'}, meta: { requireLogin: true }},

    // admin functions
    // {path: '/roles', component: gridView, props: { title: 'Roles', endpoint: server + '/roles'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/roles/new', component: addRole, props: { title: 'New Role'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/roles/new/:id', component: addRole, props: { title: 'New Role'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/roles/edit/:id', component: editRole, props: { title: 'Edit Role'}, meta: { requireLogin: true, requireRole: 'Admin' }},

    // {path: '/users', component: users, props: { title: 'Users', endpoint: server + '/users'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/users/new/:id', component: addUser, props: { title: 'Add User', endpoint: server + '/users'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/users/edit/:id', component: editUser, props: { title: 'Edit User', endpoint: server + '/users'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/users/:id/roles', component: user_roles, props: { title: 'User Roles', endpoints: {users: server + '/users', roles: server + '/roles'}}, meta: { requireLogin: true, requireRole: 'Admin' }},

    {path: '/logs', component: logs, props: { title: 'Logs', endpoint: server + '/logs'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    {path: '/logs/:id', name:'showLog', component: log, props: { title: 'Logs', endpoint: server + '/logs'}, meta: { requireLogin: true, requireRole: 'Admin' }},

    {path: '/apps/new', name:'New App', component: appForm, props: { title: 'New App', endpoint: server + '/api/apps'}, meta: { requireLogin: true }},

    {path: '/apps/edit/:id', name:'Edit App', component: appForm, props: { title: 'Edit App', endpoint: server + '/api/apps'}, meta: { requireLogin: true }},

    {path: '/apps/:id', name:'App', component: logs, props: { title: 'Logs', endpoint: server + '/api/logs'}, meta: { requireLogin: true }},
    // // {path: '/logs/:file/:id', component: log, props: { title: 'Log', endpoint: server + '/logs'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/queue', component: queue, props: { title: 'Queue', endpoint: server + '/queue'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/queuesummary', component: queuesummary, props: { title: 'Queue', endpoint: server + '/queuesummary'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    //
    // {path: '/uploadFile', component: uploadFile, props: { title: 'Upload File'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/importFile/:id', component: importFile, props: { title: 'Import File'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    //
    // {path: '/lockout', component: lockout, props: { title: 'Admin Lockout Date'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/uploads', component: gridView, props: { title: 'Uploads', endpoint: server + '/uploads'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/imports', component: gridView, props: { title: 'Imports', endpoint: server + '/imports'}, meta: { requireLogin: true, requireRole: 'Admin' }},

    // {path: '/apps', component: dashboard, props: { title: 'Apps', endpoint: server + '/apps'}, meta: { requireLogin: true, requireRole: 'Admin' }},
    // {path: '/logs/:id', name:'showLog', component: log, props: { title: 'Logs', endpoint: server + '/logs'}, meta: { requireLogin: true, requireRole: 'Admin' }},

    { path: '*', component: notFound }
  ]
})

window.Auth = {  // to do, send roles with token ?

  isLoggedIn() {
    var jwt = JSON.parse(localStorage.getItem("jwt"));
    console.log(jwt);
    if(typeof(jwt) == 'undefined' || jwt == null) return false; // not even logged in
    return true;
  },
  logout() {
    localStorage.removeItem("jwt");
  },
  login(jwt) {
    localStorage.setItem("jwt",JSON.stringify(jwt));
  },
  token() {
    var jwt = JSON.parse(localStorage.getItem("jwt"));
    //console.log(user);
    if(typeof(jwt) == 'undefined' || jwt == null) return false; // not even logged in
    return jwt;
  }
}

// this lets you use Auth inside templates
Vue.prototype.Auth = window.Auth;

// this lets you use window inside templates
//Vue.prototype.window = window;

router.beforeEach((to, from, next) => {
  //console.log([to,from,next]);
  if(to.meta.requireLogin && !Auth.isLoggedIn()) {
    console.log("login required, redirecting");
    router.replace({path: '/login', query: { to: to.path }});
  }
  // if(to.meta.requireRole && !Auth.is(to.meta.requireRole)) {
  //   console.log("login required, redirecting");
  //   router.replace({path: '/login', query: { to: to.path }});
  // }

  next();
})

router.afterEach((to, from) => {
    document.title = to.meta.title || "Fractions";
});

const app = new Vue({
    //store,
    router,
    render: createEle => createEle(App)
}).$mount('#app');

window.checkResponse = function(response) {
  console.log("status: "+response.status );
    if(response.status == 403) {
      console.log("login required, redirecting");
      window.Auth.logout();
      router.replace({path: '/login', query: { to: router.history.current.path.substring(1) }});
    }
    else if(response.status == 451) {
      console.log("new TOS, redirecting");
      router.push({path: '/acceptance', query: { to: router.history.current.path.substring(1) }});
    }
    else if(response.status == 500) {
      router.replace({path: '/error', query: { to: router.history.current.path.substring(1) }});
    }
    else if(response.status == 404) {
      router.replace({path: '/notFound', query: { to: router.history.current.path.substring(1) }});
    }
    else if(response.status == 307) {
      response.text().then(txt => {
        console.log("server is redirecting to: " , txt);

        router.push({path: txt, query: { to: router.history.current.path.substring(1) }});
      });
    }
    else {
      return response;
    }
}

// this lets you use Auth inside templates
Vue.prototype.checkResponse = window.checkResponse;
