
<template>
    <div>
      <div class="container p-1">
        <div class="row mt-5 mb-4">
          <h2>{{ attributes._id.$oid.length ?  "Update App" : "Create App" }}</h2>
          <div class="ml-auto"><router-link to="/" class="ml-auto btn btn-primary"><i class="fa fa-arrow-left"></i> Dashboard</router-link></div>
        </div>
        <div class="card card-body bg-light mb-4">
          <div class="form-group">
            <label class="col-sm-2 col-form-label">Name</label>
            <input type="text" class="form-control" v-model="attributes.name">
          </div>
        </div>
        <div class="card card-body bg-light mb-4">
          <div class="form-group">
            <label class="col-sm-2 col-form-label">Description</label>
            <input type="text" class="form-control" v-model="attributes.description">
          </div>
        </div>

        <div class="text-center">
          <button @click="save" class="btn btn-success ml-auto mr-4"><i class="fa fa-check"></i> Save</button>
          <button @click="$router.go(-1)" class="btn btn-secondary mr-auto"><i class="fa fa-times"></i> Cancel</button>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        attributes: {
          _id: { '$oid' : ''},
          name: '',
          description: ''
        }
      }
    },
    created() {
      if(this.$route.params.id) {
        this.fetchItem(this.$route.params.id);
      }
    },
    methods: {
      fetchItem(id) {
        fetch('/api/apps/'+id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + window.Auth.token()
          }
        })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            this.attributes = res;
            this.attributes.id = null;
          })
          .catch(err => console.log(err));
      },
      save() {
        var url = '/api/apps';
        if(this.attributes._id.$oid.length) url = '/api/apps/' + this.attributes._id.$oid;
        fetch(url ,{
          method: 'post',
          body: JSON.stringify(this.attributes),
          headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + window.Auth.token()
          }
        })
        .then(res => this.checkResponse(res))
        .then(res => res.json())
        .then( data => {
          alert('App Saved');
          this.attributes = {
            _id: '',
            name: '',
            description: ''
          };
          window.location = "/";
        })
        .catch(err => console.log(err))
      }
    }
  }
</script>
