
<template>
    <div class="widget-container">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="p-3"><span class="text-primary">App</span> <span class="text-dark"><i class="fa fa-tachometer-alt"></i></span>  <span class="text-success">Dashboard</span></h1>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <router-link to="/apps/new" class="btn btn-success mb-4">Create New App</router-link>
        </div>

          <!-- <physicaldeals-widget class="widget col-xl-5 col-lg-12 col-md-12 col-sm-12" v-resize:debounce="onResize"></physicaldeals-widget> -->
          <!-- <physicaldeals-widget class="widget" v-resize:debounce="onResize"></physicaldeals-widget> -->
          <!-- <gda-prices-widget class="widget col-xl-5 col-lg-12 col-md-12 col-sm-12" v-resize:debounce="onResize"></gda-prices-widget> -->
          <!-- <gda-prices-widget class="widget" v-resize:debounce="onResize"></gda-prices-widget> -->
          <!-- <credit-alerts-widget class="widget" v-resize:debounce="onResize"></credit-alerts-widget> -->
          <!-- <stats-widget class="widgetX" v-resize:debounce="onResize"></stats-widget> -->


          <div v-for="app in apps" class="card card-body bg-light mb-4">
            <a class="row" :href="'/apps/'+app._id.$oid">
              <div class="col-10">
                <div><h3>{{ app.name }}</h3></div>
                <div><h5>{{ app.description }}</h5></div>
                <div>{{ app.app_key }}</div>
              </div>
              <!-- <div class="col-2 text-right"><router-link to="/apps/+app.key" class="btn btn-large btn-primary">Go</router-link></div> -->
            </a>
          </div>
      </div>

    </div>
</template>

<script>
  // import statsWidget from '../components/stats-widget.vue'
  // import resize from 'vue-resize-directive'

  export default {
    name: 'dashboard',

    props: {
      endpoint : {
        default: function() {
          return null;
        }
      },
      title : {
        default: function() {
          return null;
        }
      }
    },
    data() {
      return {
        apps: []
      }

    },
    methods: {
      onResize(e) {
        console.log(e);
      },
      fetchApps() {
        fetch('/api/apps', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + window.Auth.token()
          }
        })
        .then(res => checkResponse(res))
        .then(res => res.json())
        .then(res => {
          console.log(res);
          this.apps = res.apps;
        })
        .catch(err => console.log(err))
      },
    },
    created() {
      // window.Auth.logout();
      this.fetchApps();
    },
    components: {

      // statsWidget
    },
    directives: {
        // resize,
    }
  }
</script>

<style >

a.row, a.row:visited, a.row:hover {
  color: inherit;
  /* color: #000; */
  text-decoration:  none;
}

.widget-container {
  /* background-color: #c1c7cc; */
  /* background-color: #d0d6db; */
  /* background-color: #e2e6ea; */
  /* background-color: #d8d8d8; */
  width: 100vw;
  height: calc(100vh - 2em);
  /* border-radius: 12px; */
  padding: 15px;
}
.widget {
  display: inline-block;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 4px;
  margin: 10px;
  /* max-height: 500px; */
  /* max-width: 48vw; */
  overflow-x: auto;
  overflow-y: auto;
  resize: both;
}
</style>
